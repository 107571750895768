tinymce.PluginManager.add('customhr', function (editor) {
    var data = {
        align: 'left'
    };

    function onAlignButtonClick(align, alignButtons) {
        var alignButtonsKeys = Object.keys(alignButtons);

        for (var i = 0; i < alignButtonsKeys.length; i++) {
            var key = alignButtonsKeys[i];
            alignButtons[key].active(key === align);
        }

        alignButtons[align].focus();
        data.align = align;
    }

    function resetAlignData () {
        data.align = 'left';
    }

    function createHrElementStringFromParameters(formData, align) {
      var hrElementString = '<hr style="';
      var style = 'box-sizing: border-box; border-style: solid;';
      if (formData.color) {
          style += 'border-color: ' + formData.color + ';';
      }
      if (formData.height) {
          style += 'border-width:' + formData.height + ';';
      }

      if (align) {
          if (align === 'left') {
              style += 'margin-left: 0;';
          } else if (align === 'right') {
              style += 'margin-right: 0;';
          }
      }

      hrElementString += style + '" />';

      return hrElementString;
    }

    editor.addButton('customhr', {
        icon: 'hr',
        tooltip: 'Horizontal line',
        onclick: function () {
            var alignButtons = {
                left: tinymce.ui.Factory.create({
                    type: 'button',
                    icon: 'alignleft',
                    active: true
                }),
                center: tinymce.ui.Factory.create({
                    type: 'button',
                    icon: 'aligncenter',
                    active: false
                }),
                right: tinymce.ui.Factory.create({
                    type: 'button',
                    icon: 'alignright',
                    active: false
                })
            };

            alignButtons.left.on('click', onAlignButtonClick.bind(this, 'left',  alignButtons));
            alignButtons.center.on('click', onAlignButtonClick.bind(this, 'center', alignButtons));
            alignButtons.right.on('click', onAlignButtonClick.bind(this, 'right', alignButtons));

            editor.windowManager.open({
                title: 'Add horizontal line',
                name: 'customhrwindow',
                body: [
                    {
                        type: 'buttongroup',
                        label: 'Align',
                        style: 'margin: 0; padding: 0;',
                        items: [
                            alignButtons.left,
                            alignButtons.center,
                            alignButtons.right
                        ]
                    },
                    {
                        type: 'textbox',
                        name: 'color',
                        label: 'Color',
                        tooltip: 'Optional color in hsl or hash format',
                    },
                    {
                        type: 'textbox',
                        name: 'height',
                        label: 'Height',
                        tooltip: 'Optional horizontal line height',
                    }
                ],
                onsubmit: function (e) {
                    editor.execCommand('mceInsertContent', false, createHrElementStringFromParameters(e.data, data.align));
                    resetAlignData();
                }
            })
        }
    })
});

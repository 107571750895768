tinymce.PluginManager.add('width', function (editor) {
    function getWidthFromElementDataset(editor) {
        var selectedElementMceStyleData = editor.selection.getNode().dataset.mceStyle;

        if (selectedElementMceStyleData) {
            var styles = selectedElementMceStyleData.split(';');
            for (var i = 0; i < styles.length; i++) {
                var match = styles[i].match(/width: (.*)/);
                if (match) {
                    return match[1];
                }
            }
        }

        return '';
    }
    function registerFormatterOnce(editor) {
        if (editor.formatter && !editor.formatter.get('width')) {
            editor.formatter.register('width', {
                inline: 'div',
                selector: '*',
                styles: {
                    width: '%width'
                }
            })
        }
    }
    editor.addButton('width', {
        text: 'Width',
        format: 'width',
        icon: false,
        onclick: function () {
            registerFormatterOnce(editor);

            editor.windowManager.open({
                title: 'Set element width',
                body: [
                    {
                        type: 'textbox',
                        name: 'width',
                        label: 'Width',
                        tooltip: 'Content width in % or px',
                        value: getWidthFromElementDataset(editor)
                    }
                ],
                onsubmit: function (e) {
                    editor.formatter.apply('width', {width: e.data.width}, editor.selection.getNode());
                }
            }, {})
        }
    })
});
